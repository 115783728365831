import axios from 'axios'
import config from './config'

export const BE_API = axios.create({
  baseURL: config.API_URL,
  headers: {
    apiKey: '93A26D8F-61D1-4AEE-AD69-B5982076BF0B'
  }
})

const apis = {
  user: {
    authenticate: token => BE_API.get('/user', { headers: { Authorization: `Bearer ${token}` } }),
    sendSms: ldap => BE_API.post('/sms/send', { ldap })
  },
  ping: {
    checkStatus: () => BE_API.get('/status')
  },
}
export default apis