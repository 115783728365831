import { useRef } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import Home from '../Home'
import Page404 from '../404'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import FeedbackButton from 'src/components/FeedbackButton'
import { WhiteLayout, PaddedContent } from 'src/common/styled'

const App = props => {
  const { user } = props

  const tangram = useRef(
    new window.Tangram({
      productId: 'a50bc060-dd2b-4d55-a6ec-29dd7fe849d6', // PUT YOUR PRODUCT ID HERE.
      userLdap: user.LDAP,
      buttonOptions: {
        // This options is needed so you don't have two buttons.
        noShowOnStartup: true
      }
    })
  )

  return (
    <WhiteLayout>
      <Header user={user} />
      <PaddedContent>
        <Switch>
          <Route component={Home} exact path={'/'} />
          <Route component={Page404} />
        </Switch>
      </PaddedContent>
      <Footer />
      <FeedbackButton onClick={() => tangram.current.open()} />
    </WhiteLayout>
  )
}
App.propTypes = {
  user: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.user.current
})
export default connect(mapStateToProps)(App)
