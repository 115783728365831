const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)

const envConfig = {
  dev: {
    // LOGIN_URL: `https://drchiodo.loca.lt/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    // API_URL: 'https://drchiodo.loca.lt'
    LOGIN_URL: `https://be.jwtsecurelogin.test.lmit.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://be.jwtsecurelogin.test.lmit.app',
    FE_DOMAIN: 'localhost'
  },
  test: {
    LOGIN_URL: `https://be.jwtsecurelogin.test.lmit.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://be.jwtsecurelogin.test.lmit.app',
    FE_DOMAIN: '.jwtsecurelogin.test.lmit.app'
  },
  prod: {
    LOGIN_URL: `https://be.jwtsecurelogin.lmit.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://be.jwtsecurelogin.lmit.app',
    FE_DOMAIN: '.jwtsecurelogin.lmit.app'
  },
}

export default envConfig[env]