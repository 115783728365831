import { useCallback, useEffect, useState } from 'react'
import { QRCodeSVG } from 'qrcode.react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button, Input, Result } from 'antd'

import { getCookie, removeCookie } from 'src/common/cookies'
import config from 'src/common/config'
import api from 'src/common/api'

import Loader from 'src/components/Loader'
import expiredQR from '../../assets/img/qrscaduto.png'

const Home = props => {
  const { intl } = props
  const [isLoading, setIsLoading] = useState(false)

  const [token] = useState(getCookie('token'))
  const [expiry] = useState(getCookie('expiry'))
  const [timer, setTimer] = useState('00:00')
  const [expirySeconds, setExpirySeconds] = useState(null)

  const [pingStatus, setPingStatus] = useState('OK')
  const [needLDAP, setNeedLDAP] = useState(true)
  const [smsStatus, setSmsStatus] = useState(0)
  const [smsMessage, setSmsMessage] = useState('')

  const checkPingStatus = async () => {
    try {
      const res = await api.ping.checkStatus()
      return res.status
    } catch (error) {
      return error.response?.status || 500
    }
  }

  useEffect(() => {
    const authCookie = getCookie('token')

    const checkStatus = async () => {
      setIsLoading(true)
      const status = await checkPingStatus()
      if (status === 200) {
        window.location.href = config.LOGIN_URL
      } else {
        setPingStatus('KO')
        setIsLoading(false)
      }
    }

    if (!authCookie) {
      checkStatus()
    }
  }, [])

  useEffect(() => {
    const date = new Date()
    const currentTimestamp = Math.floor(date.getTime() / 1000)

    setExpirySeconds(expiry - currentTimestamp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setExpirySeconds(prevValue => prevValue - 1)
    }, 1000)

    // Update timer
    if (expirySeconds > 0) {
      const minutes = Math.floor(expirySeconds / 60)
      const seconds = expirySeconds - (60 * minutes)
      setTimer(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`)
    }

    // Clear timer
    if (expirySeconds === 0) {
      setTimer('00:00')
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [expirySeconds])

  const refreshQR = useCallback(() => {
    removeCookie('token', { path: '/', domain: config.FE_DOMAIN })
    removeCookie('expiry', { path: '/', domain: config.FE_DOMAIN })
    window.location.reload()
  }, [])

  const submitLDAP = useCallback(async () => {
    const ldap = document.getElementById('ldap').value
    setIsLoading(true)
    try {
      const res = await api.user.sendSms(ldap)
      setSmsStatus(res.status)
      setSmsMessage(res.data.message)
    } catch (error) {
      setSmsStatus(error.response?.status || 500)
      setSmsMessage(
        error.response?.message ||
        intl.formatMessage({ id: 'error.title.generic' })
      )
    }
    setNeedLDAP(false)
    setIsLoading(false)
  }, [intl])

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <h1>
        <FormattedMessage id={'app.title'} />
      </h1>
      {pingStatus === 'OK' ? (
        <>
          {token && expirySeconds > 0 ? (
            <QRCodeSVG value={token} size={320} id={'qr'} />
          ) : (
            <img src={expiredQR} alt={'expired qr'} width={320} height={320} />
          )}
          <h2>{timer}</h2>
          <Button type={'primary'} size={'large'} onClick={refreshQR}>
            <FormattedMessage id={'button.refresh'} />
          </Button>
        </>
      ) : needLDAP ? (
        <>
          <Input
            id={'ldap'}
            size={'large'}
            placeholder={intl.formatMessage({ id: 'placeholder.ldap' })}
            style={{ width: 320 }}
          />
          <Button type={'primary'} size={'large'} onClick={submitLDAP}>
            <FormattedMessage id={'button.submit'} />
          </Button>
        </>
      ) : (
        <Result
          status={smsStatus === 200 ? 'success' : 'error'}
          title={smsMessage}
        />
      )}
    </>
  )
}

export default injectIntl(Home)